// @ts-ignore - Automatic, Please fix when editing this file
const { ApolloClient, HttpLink, ApolloLink } = require('@apollo/client');
// @ts-expect-error - Automatic, Please fix when editing this file
const { get, isEmpty, set } = require('lodash/fp');
const { InMemoryCache } = require('@apollo/client/cache');
// @ts-expect-error - Automatic, Please fix when editing this file
const fetch = require('node-fetch');
const { setContext } = require('@apollo/client/link/context');

const setAuthHeader = ({ isProduction, token }: { isProduction: boolean; token?: string }) =>
  setContext((_: Record<string, any>, context: Record<string, any>) => {
    if (isProduction || !token) {
      return context;
    }
    return set('headers.authorization', `Bearer ${token}`, context);
  });

// This creates the CMS client for the server side logic and CMS interactions, with the client having a similar copy of this
// code. Any changes done to this file needs to be replicated on the server's CMS client as well (particularly the createClient
// and getUri methods). These would most probably get combined when Next.js is implemented.
// @ts-expect-error - Automatic, Please fix when editing this file
const createClient = (config, dataset, mode, uri) => {
  const sanityConfig = config.get('sanity');
  const sanityApiToken = process.env.SANITY_API_TOKEN;
  const isProduction = get('NODE_ENV')(config) === 'production';
  const cache = new InMemoryCache();
  const fetchPolicy = sanityConfig.fetchPolicy.server;
  const opts = {
    cache,
    connectToDevTools: !isProduction,
    defaultOptions: {
      query: {
        fetchPolicy,
      },
      watchQuery: {
        fetchPolicy,
      },
    },
    link: ApolloLink.from([
      // draft documents are only returned with an api token
      setAuthHeader({ isProduction, token: sanityApiToken }),
      new HttpLink({
        fetch,
        uri,
      }),
    ]),
    name: 'sanity-sales',
    ssrMode: true,
  };
  return new ApolloClient(opts);
};

module.exports = createClient;
